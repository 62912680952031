import { mix, transparentize } from 'polished';

// Core colors
export const bg = `#1E252B`;
export const fg = `#f5f5f5`;

// Elements
export const hrGradient = `${fg}02, ${fg}99, ${fg}02`;
export const bgHeader = transparentize(0.02, mix(0.1, fg, bg));

export const fgFaded = mix(0.75, bg, fg);
export const captionColor = mix(0.4, bg, fg);
export const dividerColor = mix(0.85, bg, fg);

export const bgHighlight = `#191919`;
export const whiteAlpha1 = `#ffffff0a`;
export const whiteAlpha2 = `#ffffff18`;
export const whiteAlpha3 = `#ffffff2d`;
export const shadow = `#00000066`;
export const boxShadow = `1px 1px 4px ${shadow}`;
export const textShadow = `2px 2px 5px ${shadow}`;
export const underline = `#ffffff33`;
