// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-log-jsx": () => import("./../src/components/templates/Log.jsx" /* webpackChunkName: "component---src-components-templates-log-jsx" */),
  "component---src-components-templates-post-jsx": () => import("./../src/components/templates/Post.jsx" /* webpackChunkName: "component---src-components-templates-post-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */)
}

